import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {

  function requestBackend() {
    fetch(window.env.API_URL)
      .then(data => console.log(data))
      .catch(error => console.error(error));
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={ logo } className="App-logo" alt="logo"/>
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <p>API_URL: <code>{ window.env.API_URL }</code></p>
        <button onClick={ requestBackend }>Request Backend</button>
      </header>
    </div>
  );
}

export default App;
